import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"
import * as React from "react"
import { Layout } from "../components/layout"
import c1 from "../images/index/c1.jpg"
import c2 from "../images/index/c2.jpg"
import c3 from "../images/index/c3.jpg"
import { std } from "../components/common"

const pictures = [
  { id: 1, pic: c1, },
  { id: 2, pic: c2, },
  { id: 3, pic: c3, },
]

const CarouselContainer = styled.div`
  width: 100%;
  background: white;
  position: relative;
  margin: 0;
  overflow: hidden;
  max-height: calc(100vh - ${std.nav_h});
`
const CarouselTitleContainer = styled.div`
  padding: 3rem 1rem 1rem 1rem;
  width: 100%;
  z-index: 5;
  color: white;
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.8));
  text-align: left;
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 2rem;
  transition: font-size 0.3s ease;
  @media (max-width: 700px) {
    font-size: 1.5rem;
  }
  @media (max-width: 400px) {
    font-size: 1.25rem;
  }
`

const CarouselTogglerContainer = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  &>div {
    margin: 0.5rem
  }
  .toggler {
    height: 0.5rem;
    width: 1rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease;
    background: rgba(255,255,255,0.8);
    z-index: 10;
  }
  .toggler-active {
    width: 2rem;
    background: white;
  }
`

const opacityAnimation = keyframes`
from {
  opacity: 0;
}
to {
  opacity: 1;
}
`

const Carousel = (props) => {
  const [picID, setPicID] = React.useState(props.pictures[0].id)
  const lastID = props.pictures[props.pictures.length - 1].id

  React.useEffect(() => {
    const id = setInterval(() => {
      setPicID(oldVal => {
        if (oldVal < lastID) {
          return oldVal + 1
        } else {
          return 1
        }
      })
    }, 3000)
    return () => clearInterval(id)
  })

  return (
    <CarouselContainer>
      {props.pictures.map((item) => (
        <div key={item.id} style={{
          display: picID === item.id ? 'block' : 'none',
        }} css={css`animation: ${opacityAnimation} 0.5s ease-out 1`}
        >
          <img src={item.pic} style={{ width: "100%" }} alt={item.id}></img>
        </div>
      ))}
      <CarouselTitleContainer>神顶峰 十八年匠心 只为一罐好蜜</CarouselTitleContainer>
      <CarouselTogglerContainer>
        {props.pictures.map((item) => (<div
          className={picID === item.id ? 'toggler toggler-active' : 'toggler'}
          key={item.id}
          onClick={() => setPicID(item.id)}
        ></div>))}
      </CarouselTogglerContainer>
    </CarouselContainer >
  )
}

const IndexPage = () => {
  return (
    <Layout>
      <Carousel pictures={pictures}></Carousel>
      <div></div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>神顶峰 雪蜜网</title>
